var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("heads"),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "banner" }, [
          _vm._m(0),
          _c("div", { staticClass: "right" }, [
            _c("img", {
              staticStyle: { "margin-top": "100px" },
              attrs: { src: _vm.banner }
            })
          ])
        ]),
        _c("div", { staticClass: "box box1", attrs: { id: "beishouke" } }, [
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "up" }, [
              _c("img", { staticClass: "img", attrs: { src: _vm.apply1 } }),
              _c("span", { staticClass: "title" }, [_vm._v("备授课系统")])
            ]),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect11 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem1(0)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("丰富教学资源，让备课更轻松")
                ]),
                _c("span", { staticClass: "info" }, [
                  _vm._v(
                    "教师可以使用备课系统，利用数字资源，完成教案的撰写和课件的制作"
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect12 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem1(1)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("支持直接在线授课")
                ]),
                _c("span", { staticClass: "info" }, [
                  _vm._v("教师在平台备课的课件，能够在课堂上直接演示")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "images" }, [
            _c("img", { attrs: { src: _vm.apply } }),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "carousel1",
                    attrs: {
                      interval: 5000,
                      arrow: "nerver",
                      trigger: "click",
                      "indicator-position": "none"
                    },
                    on: { change: _vm.change1 }
                  },
                  _vm._l([_vm.apply11, _vm.apply12], function(item) {
                    return _c("el-carousel-item", { key: item }, [
                      _c("img", { attrs: { src: item } })
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "box box2", attrs: { id: "zuoye" } }, [
          _c("div", { staticClass: "images" }, [
            _c("img", { attrs: { src: _vm.apply } }),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "carousel3",
                    attrs: {
                      interval: 5000,
                      arrow: "nerver",
                      trigger: "click",
                      "indicator-position": "none"
                    },
                    on: { change: _vm.change3 }
                  },
                  _vm._l([_vm.apply31, _vm.apply32, _vm.apply33], function(
                    item
                  ) {
                    return _c("el-carousel-item", { key: item }, [
                      _c("img", { attrs: { src: item } })
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "up" }, [
              _c("img", { staticClass: "img", attrs: { src: _vm.apply3 } }),
              _c("span", { staticClass: "title" }, [_vm._v("作业系统")])
            ]),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect31 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem3(0)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("普通作业")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v("作业内容随需布置，支持班级通知等")
                ])
              ]
            ),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect32 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem3(1)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("测试作业")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v("结合组卷系统，教师在线组卷，直接布置测试卷作业")
                ])
              ]
            ),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect33 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem3(2)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("智能作业")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v(
                    "系统根据大数据自行推送，为每一个同学定制不同的作业试题内容"
                  )
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "box box3", attrs: { id: "zuyuejuan" } }, [
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "up" }, [
              _c("img", { staticClass: "img", attrs: { src: _vm.apply2 } }),
              _c("span", { staticClass: "title" }, [_vm._v("组卷阅卷系统")])
            ]),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect21 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem2(0)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("手动组卷")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v("教师按章节或知识点查看试题并选择，一键进行组卷")
                ])
              ]
            ),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect22 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem2(1)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("智能组卷")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v(
                    "教师只需设置要考查章节或知识点，设定题型，大数据自动分析组卷"
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect23 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem2(2)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("网上阅卷")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v("教师直接在线阅卷，考试数据直接在线统计分析")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "images" }, [
            _c("img", { attrs: { src: _vm.apply } }),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "carousel2",
                    attrs: {
                      interval: 5000,
                      arrow: "nerver",
                      trigger: "click",
                      "indicator-position": "none"
                    },
                    on: { change: _vm.change2 }
                  },
                  _vm._l([_vm.apply21, _vm.apply22, _vm.apply23], function(
                    item
                  ) {
                    return _c("el-carousel-item", { key: item }, [
                      _c("img", { attrs: { src: item } })
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "box box4", attrs: { id: "banqun" } }, [
          _c("div", { staticClass: "images" }, [
            _c("img", { attrs: { src: _vm.apply } }),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "carousel4",
                    attrs: {
                      interval: 5000,
                      arrow: "nerver",
                      trigger: "click",
                      "indicator-position": "none"
                    },
                    on: { change: _vm.change4 }
                  },
                  _vm._l([_vm.apply41], function(item) {
                    return _c("el-carousel-item", { key: item }, [
                      _c("img", { attrs: { src: item } })
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "up" }, [
              _c("img", { staticClass: "img", attrs: { src: _vm.apply4 } }),
              _c("span", { staticClass: "title" }, [_vm._v("学习空间")])
            ]),
            _c(
              "div",
              {
                class: "down " + (_vm.isSelect41 ? "select" : ""),
                on: {
                  click: function($event) {
                    return _vm.setActiveItem4(0)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [_vm._v("学习空间")]),
                _c("span", { staticClass: "info" }, [
                  _vm._v(
                    "教师创建班群，享有班级的维护管理权限。学生、家长通过班群获取学生学习信息，学校活动等"
                  )
                ])
              ]
            )
          ])
        ])
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("span", { staticClass: "title" }, [
        _vm._v("教学考评管全流程解决方案")
      ]),
      _c("span", { staticClass: "info" }, [
        _vm._v(
          "\n          服务于教育管理者、教师、学生、家长等角色，提供基于课堂的全流程智慧应用支持，最大化提高教育效率，提升教育水平。\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }