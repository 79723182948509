<template>
  <!-- 首页 -->
  <div>
    <heads />
    <div class="main">
      <div class="banner">
        <div class="left">
          <span class="title">教学考评管全流程解决方案</span>
          <span class="info">
            服务于教育管理者、教师、学生、家长等角色，提供基于课堂的全流程智慧应用支持，最大化提高教育效率，提升教育水平。
          </span>
        </div>
        <div class="right">
          <img :src="banner"
            style="margin-top:100px;">
        </div>
      </div>
      <div id="beishouke"
        class="box box1">
        <div class="message">
          <div class="up">
            <img :src="apply1"
              class="img">
            <span class="title">备授课系统</span>
          </div>
          <div :class="`down ${isSelect11?'select':''}`"
            @click="setActiveItem1(0)">
            <span class="title">丰富教学资源，让备课更轻松</span>
            <span class="info">教师可以使用备课系统，利用数字资源，完成教案的撰写和课件的制作</span>
          </div>
          <div :class="`down ${isSelect12?'select':''}`"
            @click="setActiveItem1(1)">
            <span class="title">支持直接在线授课</span>
            <span class="info">教师在平台备课的课件，能够在课堂上直接演示</span>
          </div>
        </div>
        <div class="images">
          <img :src="apply">
          <div class="image">
            <el-carousel ref="carousel1"
              :interval="5000"
              arrow="nerver"
              trigger="click"
              indicator-position="none"
              @change="change1">
              <el-carousel-item v-for="item in [apply11,apply12]"
                :key="item">
                <img :src="item">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div id="zuoye"
        class="box box2">

        <div class="images">
          <img :src="apply">
          <div class="image">
            <el-carousel ref="carousel3"
              :interval="5000"
              arrow="nerver"
              trigger="click"
              indicator-position="none"
              @change="change3">
              <el-carousel-item v-for="item in [apply31,apply32,apply33]"
                :key="item">
                <img :src="item">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="message">
          <div class="up">
            <img :src="apply3"
              class="img">
            <span class="title">作业系统</span>
          </div>
          <div :class="`down ${isSelect31?'select':''}`"
            @click="setActiveItem3(0)">
            <span class="title">普通作业</span>
            <span class="info">作业内容随需布置，支持班级通知等</span>
          </div>
          <div :class="`down ${isSelect32?'select':''}`"
            @click="setActiveItem3(1)">
            <span class="title">测试作业</span>
            <span class="info">结合组卷系统，教师在线组卷，直接布置测试卷作业</span>
          </div>
          <div :class="`down ${isSelect33?'select':''}`"
            @click="setActiveItem3(2)">
            <span class="title">智能作业</span>
            <span class="info">系统根据大数据自行推送，为每一个同学定制不同的作业试题内容</span>
          </div>
        </div>
      </div>
      <div id="zuyuejuan"
        class="box box3">

        <div class="message">
          <div class="up">
            <img :src="apply2"
              class="img">
            <span class="title">组卷阅卷系统</span>
          </div>
          <div :class="`down ${isSelect21?'select':''}`"
            @click="setActiveItem2(0)">
            <span class="title">手动组卷</span>
            <span class="info">教师按章节或知识点查看试题并选择，一键进行组卷</span>
          </div>
          <div :class="`down ${isSelect22?'select':''}`"
            @click="setActiveItem2(1)">
            <span class="title">智能组卷</span>
            <span class="info">教师只需设置要考查章节或知识点，设定题型，大数据自动分析组卷</span>
          </div>
          <div :class="`down ${isSelect23?'select':''}`"
            @click="setActiveItem2(2)">
            <span class="title">网上阅卷</span>
            <span class="info">教师直接在线阅卷，考试数据直接在线统计分析</span>
          </div>
        </div>
        <div class="images">
          <img :src="apply">
          <div class="image">
            <el-carousel ref="carousel2"
              :interval="5000"
              arrow="nerver"
              trigger="click"
              indicator-position="none"
              @change="change2">
              <el-carousel-item v-for="item in [apply21,apply22,apply23]"
                :key="item">
                <img :src="item">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div id="banqun"
        class="box box4">
        <div class="images">
          <img :src="apply">
          <div class="image">
            <el-carousel ref="carousel4"
              :interval="5000"
              arrow="nerver"
              trigger="click"
              indicator-position="none"
              @change="change4">
              <el-carousel-item v-for="item in [apply41]"
                :key="item">
                <img :src="item">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="message">
          <div class="up">
            <img :src="apply4"
              class="img">
            <span class="title">学习空间</span>
          </div>
          <div :class="`down ${isSelect41?'select':''}`"
            @click="setActiveItem4(0)">
            <span class="title">学习空间</span>
            <span class="info">教师创建班群，享有班级的维护管理权限。学生、家长通过班群获取学生学习信息，学校活动等</span>
          </div>

        </div>
      </div>
    </div>
    <foots />
    <!-- <contant /> -->
  </div>

</template>
<script>
import banner from '../assets/img/apply/apply-banner.svg';
import apply from '../assets/img/apply/apply.svg';
import apply1 from '../assets/img/apply/apply1-1.svg';
import apply2 from '../assets/img/apply/apply2-1.svg';
import apply3 from '../assets/img/apply/apply3-1.svg';
import apply4 from '../assets/img/apply/apply4-1.svg';
import apply11 from '../assets/img/apply/1.svg';
import apply12 from '../assets/img/apply/2.svg';
import apply21 from '../assets/img/apply/3.svg';
import apply22 from '../assets/img/apply/4.svg';
import apply23 from '../assets/img/apply/5.svg';
import apply31 from '../assets/img/apply/6.svg';
import apply32 from '../assets/img/apply/7.svg';
import apply33 from '../assets/img/apply/8.svg';
import apply41 from '../assets/img/apply/9.svg';

import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    const {
      query: { type = 0 },
    } = this.$route;
    return {
      type,
      banner,
      apply,
      apply1,
      apply2,
      apply3,
      apply4,
      apply11,
      apply12,
      apply21,
      apply22,
      apply23,
      apply31,
      apply32,
      apply33,
      apply41,
      // 备课系统的选中bool值,默认第一个选中,图片也是默认显示第一个
      isSelect11: 1,
      isSelect12: 0,

      isSelect21: 1,
      isSelect22: 0,
      isSelect23: 0,

      isSelect31: 1,
      isSelect32: 0,
      isSelect33: 0,

      isSelect41: true,
    };
  },
  mounted() {
    // window.addEventListener('scroll', this.test);
  },
  created() {
    if (this.type === 0) {
      document.documentElement.scrollTop = 0;
    } else if (this.type === 1) {
      document.documentElement.scrollTop = 400;
    } else if (this.type === 2) {
      document.documentElement.scrollTop = 930;
    } else if (this.type === 3) {
      document.documentElement.scrollTop = 1500;
    } else if (this.type === 4) {
      document.documentElement.scrollTop = 2027;
    }
  },
  methods: {
    /* test() {
      console.log(document.documentElement.scrollTop);
    }, */
    change1(v) {
      this.isSelect11 = v ? 0 : 1;
      this.isSelect12 = v ? 1 : 0;
    },
    setActiveItem1(v) {
      this.$refs.carousel1.setActiveItem(v);
    },

    change2(v) {
      this.isSelect21 = v === 0 ? 1 : 0;
      this.isSelect22 = v === 1 ? 1 : 0;
      this.isSelect23 = v === 2 ? 1 : 0;
    },
    setActiveItem2(v) {
      this.$refs.carousel2.setActiveItem(v);
    },

    change3(v) {
      this.isSelect31 = v === 0 ? 1 : 0;
      this.isSelect32 = v === 1 ? 1 : 0;
      this.isSelect33 = v === 2 ? 1 : 0;
    },
    setActiveItem3(v) {
      this.$refs.carousel3.setActiveItem(v);
    },

    change4(v) {
      this.isSelect41 = v === 0 ? 1 : 0;
    },
    setActiveItem4(v) {
      this.$refs.carousel4.setActiveItem(v);
    },
  },
};
</script>
<style>
/* .el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
} */
</style>
<style lang="less" scoped>
.main {
  //   max-width: 1920px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  margin-top: 80px;
  .banner {
    width: 100%;
    height: 400px;
    background: linear-gradient(137deg, rgba(113, 187, 255, 1) 0%, rgba(38, 99, 252, 1) 100%);
    display: flex;
    justify-content: center;
    .left {
      margin-right: 57px;
      .title {
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 50px;
        display: block;
        margin-top: 110px;
      }
      .info {
        width: 457px;
        height: 90px;
        font-size: 18px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: rgba(255, 255, 255, 1);
        line-height: 30px;
        display: block;
        margin-top: 28px;
      }
    }
  }
  .box {
    display: flex;
    justify-content: center;
    .message {
      width: 576px;
      margin: auto 0;

      .up {
        display: flex;
        margin-bottom: 25px;
        .title {
          height: 56px;
          font-size: 40px;
          font-family: PingFangSC-Light;
          font-weight: 300;
          color: rgba(38, 38, 38, 1);
          line-height: 50px;
          margin-left: 16px;
        }
        .img {
          width: 50px;
          height: 50px;
          display: block;
        }
      }
      .down {
        width: 100%;
        height: 106px;
        cursor: pointer;
        .title {
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
          line-height: 28px;
          display: block;
          padding-top: 24px;
          padding-left: 40px;
        }
        .info {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Light;
          font-weight: 300;
          color: rgba(140, 140, 140, 1);
          line-height: 22px;
          display: block;
          margin-top: 8px;
          padding-left: 40px;
        }
      }
      .select {
        background: rgba(242, 249, 255, 1);
        .title {
          color: rgba(24, 144, 255, 1);
        }
      }
    }
    .images {
      display: block;
      position: relative;
      .image {
        width: 530px;
        height: 300px;
        top: 64px;
        left: 36px;
        position: absolute;
      }
    }
  }
  .box1 {
    margin-top: 126px;
    .message {
      margin-right: 40px;
    }
  }
  .box2 {
    margin-top: 160px;
    .message {
      margin-left: 40px;
    }
  }
  .box3 {
    margin-top: 160px;
    .message {
      margin-right: 40px;
    }
  }
  .box4 {
    margin-top: 160px;
    margin-bottom: 160px;
    .message {
      margin-left: 40px;
      .down {
        height: 131px;
      }
    }
  }
}
</style>
